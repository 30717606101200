export default {
  longDate: function(value) {
    let date = new Date(value)
    date = date.toLocaleString("en-CA", {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
    })
    if(date == "Invalid Date") {
        date = "--"
    }
    return date
  },
  shortDate: function(value) {
    let date = new Date(value)
    date = date.toLocaleString("en-CA", {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
    })
    if(date == "Invalid Date") {
        date = "--"
    }
    return date
  }
}
