<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="trainingscheduleheader"/>
    <Toast/>
    <div  v-show="!showEventDetail">
      <div class="container-fluid quotecontainer">
        <div class="container quotetext"> 
          Jacinta Gallant is a 
          <span class="largetext">collaborative lawyer, mediator, trainer </span> 
          <span class="line"><span class="largetext">and presenter</span> in </span>
          <span> <br/>Charlottetown, Prince Edward Island.</span>
        </div>
      </div>
      <div class="container-fluid textured" style="padding-top:50px;padding-bottom:50px;">     
        <div class="container" style="text-align:left;">
          <h1>Training Schedule</h1>
          <p>We offer introductory and advanced training events that are interactive, engaging and focused on what you need to be more natural 
            and effective as a collaborative professional, mediator, or facilitator.   Click on the event for more details and to register.</p>
        </div>
      </div>
      <div class="container-fluid maincontentcontainer">     
        <div class="container" style="text-align:left;">
          <div class="row">
            <div class="col-xs-12 col-sm-8">
              <div class="row" v-if="events">
                  <div class="col-xs-12 event-list-box">
                    <div class="row" v-for="(event,index) in events" :key="index">
                      <div class="col-xs-12 col-sm-3">
                          <img :src="baseURL + eventImagesPath + event.imageFilename" class="img-responsive rounded"  v-if="event.imageFilename">
                      </div>
                      <div class="col-xs-12 col-sm-9">
                        <h3 class="event-title" v-html="event.title"></h3>
                        <h4 style="font-size:12pt;" v-html="event.subTitle"></h4>
                        <!-- | <span class="post-date">{{postDate(event.date)}} and {{postDate(event.endDate)}}</span></h4> -->
                      <div style="width:100%;">
                        <div style="width:80px;margin-top:-10px; margin-bottom:-10px;">
                          <hr/>
                        </div>
                      </div>
                        <p v-html="postDescription(event.description)"></p>
                      
                        <div @click="showDetail(event)" class="summer-session-list-button">See Event Details 
                          <span class="glyphicon glyphicon-chevron-right"></span>
                        </div> &nbsp;
                        <a class="summer-session-list-button" :href="`http://${event.externalRegistrationURL}`" target="_blank" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'open'">
                          Register Now <span class="glyphicon glyphicon-chevron-right"></span>
                        </a>
                        <span class="summer-session-list-button opening" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'notYet'">
                          Registration Opening <span class="glyphicon glyphicon-calendar"></span>
                        </span>
                        <span class="summer-session-list-button closed" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'closed'">
                          Closed for Registration <span class="glyphicon glyphicon-remove-circle"></span>
                        </span>
                        <span class="summer-session-list-button sold-out" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'soldOut'">
                          Sold Out <span class="glyphicon glyphicon-exclamation-sign"></span>
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row" v-else>
                No Events Available!
              </div>
            </div>
                <div class="col-xs-12 col-sm-4 summer-session-list-box" v-if="summerSessionCount >= 1">
                  <div class="row">
                    <img src="../assets/images/lighthouse.jpg" style="margin-top:0px;" class=" img-responsive ">
                  </div>
                  <h2><a @click="goSummerSessions()">Summer Sessions for Conflict Professionals</a></h2>
                  <p>Join us for this unique opportunity for professional development on beautiful Prince Edward Island, Canada. 
                    Each summer, we offer advanced training to conflict resolution professionals who want to be more natural and 
                    effective in their work. Come to Prince Edward Island to for a deeply engaging professional development experience, 
                    and enjoy a vacation on our Island!</p>
                  
                  <a class="summer-session-list-button" @click="goSummerSession(summerEvent)" v-for="(summerEvent, index) in summerSessions" :key="index"> 
                    <span class="glyphicon glyphicon-chevron-right"/>  <div style="font-weight: normal; font-size: 12px;" v-html="summerEvent.title" />
                  </a>
                  <br>
                </div>
            </div>
          </div>
      </div>
    </div>
    <event-detail
      v-if="showEventDetail"
      :event="event"
      :trainer="trainer"
    />
    <Footer/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

// Components
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import EventDetail from '../components/EventDetail.vue'

// Helpers
import Dates from '../methods/Date.js'
import moment from 'moment'

// API Services
import EventServices from '../services/EventServices.js'

export default {
  name: 'Events',
  components: {
    Header,
    Footer,
    EventDetail
  },
  setup() {
    const router = useRouter()
    const toast = useToast()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const eventImagesPath = process.env.VUE_APP_EVENT_IMAGES

    var events = ref([])
    var summerSessions = ref([])
    var event = ref()
    const summerSessionCount = ref() 
    var trainer = ref()
    var showEventDetail = false

    onMounted(() => {
      EventServices.getAvailableEvents()
      .then((response) => {
        events.value = response.data
      })
      .catch((error) => {
        if(error.message) {
          toast.add({severity:'error', summary: 'Error', detail:error.response.data.reason, life: 5000})
        }
      })
      EventServices.getAllSummerSessions()
      .then((response) => {
        summerSessions.value = response.data
        summerSessionCount.value = summerSessions.value.length
      })
    })

    const postDate = (value) => {
      return Dates.longDate(value)
    }

    const postDescription = (value) => {
      let stripped = value.replace(/<\/?[^>]+>/ig, " ")
      stripped = stripped.replace('&nbsp;', " ")
      return stripped.substring(0,250) + "..."
    }

    const postStatus = (regOpen,regClose,seats,externalRegistrationURL) => {
      if(moment.utc(regOpen).isAfter(moment.utc(Date.now()))) {
        return "notYet"
      }
      if(regClose && moment.utc(regClose).isSameOrBefore(moment.utc(Date.now()))) {
        return "closed"
      }
      if(!externalRegistrationURL && seats === 0) {
        return "soldOut"
      }
      if(!externalRegistrationURL) {
        return "Registration not Available"
      }
      return "open"
    }

    const showDetail = (event) => {
      router.push({ name:"EventDetail", query:{ e: event.id}})
    }

    const goSummerSessions = () => {
      router.push({ name:"Summer Sessions"})
    }

    const goSummerSession = (summerSession) => {
      router.push({ name:"Summer Session Detail", query:{ e: summerSession.id }})
    }

    return {
      router,
      toast,
      environment,
      baseURL, 
      eventImagesPath,
      events,
      event,
      trainer,
      postDate,
      postDescription,
      postStatus,
      showDetail,
      showEventDetail,
      summerSessions,
      summerSessionCount,
      goSummerSessions,
      goSummerSession
    }
  }
}
</script>

<style lang="scss">
</style>
