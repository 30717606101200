<template>
  <div>
    <div class="container-fluid quotecontainer">
      <div class="container quotetext"> 
        Jacinta Gallant is a 
        <span class="largetext">collaborative lawyer, mediator, trainer </span> 
        <span class="line"><span class="largetext">and presenter</span> in </span>
        <span> <br/>Charlottetown, Prince Edward Island.</span>
      </div>
    </div>

    <div class="container-fluid textured" style="padding-top:50px; padding-bottom:50px;">     
    <div class="container">
      <div class="row">
        <div class="col-xs-7">
          <h1 style="margin-top: 0px;">Event Details</h1>
        </div>
        <div class="col-xs-5">
          <div @click="showAllEvents()" class="back-button"><span class="glyphicon glyphicon-chevron-left"></span> All Events</div>
        </div>
      </div>
      <h2>{{ event.title }}</h2>
      <h3>{{ event.subTitle }}
        <span class="subtitle-info sold-out">- Sold Out 
          <span class="glyphicon glyphicon-exclamation-sign"></span>
        </span>
        
        <span class="subtitle-info opening">- Registration Opening <b>SOME DATE</b> 
          <span class="glyphicon glyphicon-calendar"></span>
        </span>
        
        <span class="subtitle-info closed">- Closed for Registration 
          <span class="glyphicon glyphicon-remove-circle"></span>
        </span>
        
      </h3>
      <p class="post-date">{{manicuredDate(event.date)}} and {{manicuredDate(event.endDate)}}</p>
      <p class="event-location">{{ event.location }}</p>
      <br/>   
      </div>
    </div>    

    <div class="container-fluid maincontentcontainer">     
    <div class="container">
      <div>
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active"><a href="#about" aria-controls="about" role="tab" data-toggle="tab">About</a></li>
        
        <li role="presentation"><a href="#program" aria-controls="program" role="tab" data-toggle="tab">Program</a></li>
        
        <li role="presentation"><a href="#accommodations" aria-controls="accommodations" role="tab" data-toggle="tab">Accommodations</a></li>
        
        <li role="presentation"><a href="#trainers" aria-controls="trainers" role="tab" data-toggle="tab">Trainers</a></li>
        
        <li role="presentation"><a href="#register" aria-controls="Register" role="tab" data-toggle="tab">Register</a></li>
        
      </ul>
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane anchor active" id="about">
          <h3 style="text-align: left;">About This Event</h3>
          <div class="row">
            <div class="col-xs-12 col-sm-8">
              <p v-html="event.description" style="text-align: left;"></p>
              
              <div class=" col-xs-12 col-sm-6 col-md-4 price-box">
                <h3 style="text-align: left;">Event Cost</h3>
                <h4>${{ event.cost }} CAD</h4>
                
                <small style="font-size: 73%; float: right;">Plus tax</small>
                
              </div>
              
            </div>
            <div class="col-xs-12 col-sm-4">
              <img :src="`../user-content/events/${event.imageFilename}`" class="img-responsive">
            </div>
          </div>
        </div>

        <div role="tabpanel" class="tab-pane anchor " id="program">
          <h3 style="text-align: left;">Event Program</h3>
          <p v-html="event.program" style="text-align: left;"></p>
        </div>
        
        <div role="tabpanel" class="tab-pane anchor " id="accommodations">
          <h3 style="text-align: left;">Event Accommodations</h3>
          <p v-html="event.accomodation" style="text-align: left;"></p>
          
        </div>
        
        <div role="tabpanel" class="tab-pane anchor " id="register">
          <div class=" col-xs-12 col-sm-8">
            <h3 style="text-align: left;">Register For This Event</h3>
          </div>
          
          <div class=" col-xs-12 col-sm-6 col-md-4 price-box">
            <h3 style="text-align: left;">Event Cost</h3>
            <h4>${{ event.cost }} CAD</h4>
            
            <small style="font-size: 73%; float: right;">Plus tax</small>
            
          </div>
          
          
            <div class="col-xs-12"> Registration for this event can be completed externally!<br/><br/><br/>
            <a  class="gotobutton"  href="" target="_blank">Visit Registration Website</a><br/><br/><br/></div>
          
          <form method="post" id="regForm" class="form-horizontal">
            <p style="text-align: left;">
              Fill out the form below to register for this event!
            </p>
            <input type="hidden" name="eventId" value="<?=$event['guid']?>">
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Full Name <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="name"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Profession </label>
              <div class="col-xs-12 col-sm-5">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="profession"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Phone <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="phone"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Email <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="email"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Confirm Email <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="confirmEmail"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for=""></label>
              <div class="col-xs-12 col-sm-3">
                <h3 style="text-align: left;">Address</h3>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Street/PO Box <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="street"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Town/City <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="city"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Country <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-3" style="text-align: left;">
                <div class="btn-group">
                  <select class="form-control" name="countryDdl">
                    <option value="">Select...</option>
                    <option value="Canada">Canada</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div id="countryOther" style="display:none;">
                <label class="col-xs-12 col-sm-1 control-label" for="">Other <span class="required">*</span></label>
                <div class="col-xs-12 col-sm-3">
                  <input maxlength="60" type="text" class="formwidthmedium form-control" name="countryText"/>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm" id="provContainer" style="display:none;">
              <label class="col-xs-12 col-sm-2 control-label" for="">Province/State <span class="required">*</span></label>
              <div id="provList">
                <div class="col-xs-12 col-sm-3">
                  <div class="btn-group">
                    <select class="form-control" name="provinceDdl">
                      <option value="">Select...</option>
                      
                    </select>
                  </div>
                </div>
              </div>
              <div id="provText">
                <div class="col-xs-12 col-sm-3">
                  <input maxlength="60" type="text" class="formwidthmedium form-control" name="provinceText"/>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Postal/ZIP Code <span class="required">*</span></label>
              <div class="col-xs-12 col-sm-2">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="postalCode"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for=""></label>
              <div class="col-xs-12 col-sm-6">
                <h3 style="text-align: left;">Billing Address <small>(If different from above)</small></h3>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Company Name </label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingCompany"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Street/PO Box</label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingStreet"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Town/City</label>
              <div class="col-xs-12 col-sm-3">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingCity"/>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Country</label>
              <div class="col-xs-12 col-sm-3">
                <div class="btn-group">
                  <select class="form-control" name="billingCountryDdl">
                    <option value="">Select...</option>
                    <option value="Canada">Canada</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div id="billingCountryOther" style="display:none;">
                <label class="col-xs-12 col-sm-1 control-label" for="">Other</label>
                <div class="col-xs-12 col-sm-3">
                  <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingCountryText"/>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm" id="billingProvContainer" style="display:none;">
              <label class="col-xs-12 col-sm-2 control-label" for="">Province/State</label>
              <div id="billingProvList">
                <div class="col-xs-12 col-sm-3">
                  <div class="btn-group">
                    <select class="form-control" name="billingProvinceDdl">
                      <option value="">Select...</option>
                      
                    </select>
                  </div>
                </div>
              </div>
              <div id="billingProvText">
                <div class="col-xs-12 col-sm-3">
                  <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingProvinceText"/>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="">Postal/ZIP Code</label>
              <div class="col-xs-12 col-sm-2">
                <input maxlength="60" type="text" class="formwidthmedium form-control" name="billingPostalCode"/>
              </div>
            </div>
            <div class="form-group ">
              <div class="row">
                <label class="col-xs-12 col-sm-2"></label>
                <label class="col-xs-12 col-sm-6 control-label" style="text-align:left;">
                If you have fewer than 5 years in practice, or if your circumstances are such that you cannot afford to pay the 
                full fee right now, please let us know in the comment box below. We will be in touch to advise you of discount 
                or payment options that could work for you.
                </label>
              </div>
              <div class="row">
                <label class="col-xs-12 col-sm-2"></label>
                <div class="col-xs-12 col-sm-6">
                  <textarea class="form-control" rows="4" name="comments"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" >Price </label>
              <label class="col-xs-12 col-sm-10 control-label" style="text-align: left;">$<span id="price">{{ event.cost }}</span></label>
            </div>
            <div class="form-group form-group-sm" id="taxContainer">
              <label class="col-xs-12 col-sm-2 control-label" >Tax ()</label>
              <label class="col-xs-12 col-sm-10 control-label" style="text-align: left;">tax amount</label>
            </div>
            <div class="form-group form-group-sm" id="totalContainer">
              <label class="col-xs-12 col-sm-2 control-label" >Total </label>
              <label class="col-xs-12 col-sm-10 control-label" style="text-align: left;">${{ event.cost }}</label>
            </div>
            <div class="form-group form-group-sm">
              <label class="col-xs-12 col-sm-2 control-label" for="payment"><!--Payment Method <span class="required">*</span>--></label>
              <div class="col-xs-12 col-sm-10">
                <!-- <?=$payMethods?> -->
                <div style="background-color:#c8c8ce;border:3px solid #a5a5b8; padding:15px; font-weight:bold;border-radius:5px;">You will be sent an invoice with payment instructions.</div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-12 col-sm-2 control-label" >&nbsp; </label>
              <div class="col-xs-12 col-sm-10">
                <div id="captchaContainer"></div>
              </div>
            </div>
            <div class="form-group">
              <label>&nbsp;</label>
              <div class="formwidthmedium">
                <button type="submit" class="btn-orange btn btn-default">Submit</button>
              </div>
            </div>
          </form>
          <div id="doneMsg" style="display:none;">
            <b style='font-size: 18px;'>Thank you for your registration! </b><br/>
            <div><b>You will receive an email shortly with payment instructions for this event. 
            If you don't receive the email within a few minutes, check your junk or spam folder.<br/></b></div>
          </div>
          <div id="doneMsgPaypal" style="display:none;">
            <b style='font-size: 18px;'>Thank you for your registration!</b><br/>
            <div><b>You will receive an email shortly confirming your registration for this event. 
            If you don't receive the email within a few minutes, check your junk or spam folder.<br/></b></div>
            <div id="paypal_form" style="text-align: center; border: 3px solid #d40202; padding: 15px;margin:20px 200px 20px 200px; border-radius: 15px;">
              To complete your transaction with PayPal, click the button below:
              <form action="<?=PAYPAL_POST_URL?>" method="post">
                <input type="hidden" name="lc" value="EN">
                <input type="hidden" name="cmd" value="_xclick">
                <input type="hidden" name="upload" value="1">
                <input type="hidden" name="business" value="<?=PAYPAL_MERCHANT_EMAIL?>">
                <input type="hidden" name="item_name" value="<?=$event['title']?> Registration">
                <input type="hidden" name="amount" value="<?=number_format($event['cost'], 2)?>">
                <input type="hidden" name="tax" value="<?=number_format($event['tax'], 2)?>">
                <input type="hidden" name="currency_code" value="CAD">
                <input type="hidden" name="custom" value="">
                <input type="hidden" name="return" value="http://<?=$_SERVER['HTTP_HOST'].ROOT?>event-details.php?e=<?=$event['guid']?>">
                <input type="image"  src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif" name="submit" alt="Make payments with PayPal - it's fast, free and secure!">
              </form>
            </div>
          </div>         
        </div>       
        <div role="tabpanel" class="tab-pane anchor " id="trainers" style="text-align: left;">
          <h3>Meet The Trainers</h3>       
            <img :src="`../user-content/trainers/${trainer.imageFilename}`" style="float:right;margin-top:20px;margin-right: 10px;width:150px;">      
            <h4> {{ trainer.name }} <span class="event-location"> {{ trainer.title }}</span></h4>
            <p class="event-location">{{ trainer.home }}</p>
            <p>{{ trainer.biography }}</p>
            <br><br>
        
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
// Components

// API Services

// Client side messaging

export default {
  name: 'EventDetail',
  props: ['event','trainer'],
  data() {
    return {
      
    }
  },
  methods: {
    manicuredDate(value) {
      let date = new Date(value)
      date = date.toLocaleString("en-CA", {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
      })
      if(date == "Invalid Date") {
          date = "--"
      }
      return date
    },
    showAllEvents() {
      this.$emit("hideDetail")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
